$font-stack: sofia-pro, sans-serif;
$font-stack-alt: 'Didot', serif;
$font-icons: "icomoon";

$base-size: 16px;
$line-height: 1.6;

$extra-thin: 100;
$thin: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$color-white: rgb(255, 255, 255);
$color-light: rgb(236, 234, 233);
$color-grey: rgb(111, 111, 111);
$color-dark: rgb(29, 29, 27);
$color-black: rgb(0, 0, 0);
$color-primary: rgb(56, 128, 175);
$color-secondary: rgb(227, 195, 201);
$color-tertiary: rgb(233, 209, 213);
$color-accent: rgb(166, 215, 210);
$color-danger: rgb(255, 0, 0);
