.header-wrap {
	margin-bottom: rem(15px);

	.header-group {
		display: flex;
		align-items: flex-end;
		height: rem(80px);

		@media (min-width: $lg) {
			height: rem(130px);
		}
	}

	.button-bars {
		font-size: rem(32px);
		line-height: rem(20px);

		@media (min-width: $lg) {
			display: none;
		}
	}

	.form-search-wrap {
		position: relative;

		@media (max-width: $md) {
			position: fixed;
			z-index: 10008;
			top: 0;
			left: -100vw;
			width: 100%;
			height: 100%;
			min-height: 100%;
			overflow-x: hidden;
			overflow-y: scroll;
			border: 1px solid $color-tertiary;
			background: $color-white;
		}
	}

	.form-search-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: rem(55px);
		padding: 0 rem(30px);

		@media (min-width: $lg) {
			display: none;
		}

		.button-hide {
			padding: 0;
			font-size: rem(24px);
		}
	}

	.form-search-content {
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 55px);

		@media (min-width: $lg) {
			align-items: flex-end;
			justify-content: flex-start;
			height: 100%;
		}
	}

	.justify-right {
		justify-content: flex-end;
	}

	.site-logo {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		width: 100%;
		margin: 0;

		@media (max-width: $md) {
			img {
				transform: scale(0.6);
				transform-origin: bottom center;
			}
		}
	}
}
