.widget-options {
	.option {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem(6px) rem(6px) rem(6px) rem(10px);
		background: $color-light;
		font-size: rem(14px);
		font-weight: $light;

		.button {
			margin: 0;
			padding: 0;
			line-height: rem(14px);
		}
	}
}
