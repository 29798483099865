.topbar-wrap {
	height: rem(30px);
	background: $color-light;

	.topbar-text {
		display: none;

		@media (min-width: $lg) {
			display: flex;
			align-items: center;
			justify-content: center;
			height: rem(30px);
			font-family: $font-stack-alt;
			font-size: rem(14px);

		}
	}


	.button-show {
		height: rem(30px);
		font-family: $font-stack-alt;
		font-size: rem(14px);
		font-style: italic;

		@media (min-width: $lg) {
			display: none;
		}
	}

	.topbar-group {
		display: flex;

		.topbar-text {
			width: 100%;
			margin-right: rem(15px);
			border-right: rem(4px) solid $color-white;
		}
	}

	.topbar-lang {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: rem(30px);

		@media (min-width: $lg) {
			width: auto;
		}
	}

	.lang-selector {
		border: 0;
		outline: 0;
		background: transparent;
		font-size: rem(10px);
		font-weight: $bold;
		text-transform: uppercase;

		option {
			display: block;
			margin: rem(15px);
			background: $color-light;
			line-height: rem(20px);
		}
	}
}
