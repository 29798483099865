.slider-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: rem(30px);

	@media (min-width: $lg) {
		height: rem(380px);
		margin-bottom: 0;
	}

	.header-subtitle {
		display: block;
		margin-bottom: rem(10px);
		font-size: rem(11px);
		font-weight: $light;
		text-transform: uppercase;
	}

	.header-title {
		font-family: $font-stack-alt;
		font-size: rem(48px);
		line-height: rem(56px);
	}

	.header-link {
		margin-top: rem(15px);

		a {
			display: inline-block;
			padding: rem(10px) rem(20px);
			transition: all 0.3s ease;
			background: $color-dark;
			color: $color-white;
			font-size: rem(10px);
			text-decoration: underline;
		}

		a:hover {
			background-color: $color-tertiary;
			color: $color-dark;
		}
	}
}
