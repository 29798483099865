.widget-categories {
	.categories-list,
	.list-sub {
		margin: 0 0 rem(15px);
		padding: 0;
		list-style: none;
	}

	.list-sub {
		display: none;
	}

	.show {
		.icon {
			transform: rotate(-180deg);
		}
	}

	.list-link,
	.sub-link {
		display: block;
		color: $color-dark;
		font-size: rem(14px);
	}

	.toggler {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(5px);
		padding-right: rem(6px);
		padding-bottom: rem(5px);
		border-bottom: rem(1px) solid $color-light;
		font-family: $font-stack-alt;
		font-size: rem(16px);
		font-weight: $bold;
	}

	.icon {
		transition: transform 0.3s ease;
		font-size: rem(12px);
	}
}
