

.sidebar-shop {
	width: 25%;
	padding-right: rem(50px);

	@media(max-width: $md) {
		position: fixed;
		z-index: 10006;
		top: 0;
		left: -100vw;
		width: 100%;
		height: 100%;
		min-height: 100%;
		padding: rem(30px);
		overflow-x: hidden;
		overflow-y: scroll;
		background: $color-white;
	}

	.sidebar-shop-header {
		display: flex;
		justify-content: flex-end;
		padding-bottom: rem(15px);

		@media (min-width: $lg) {
			display: none;
		}

		.button-hide {
			padding: 0;
			font-size: rem(24px);
		}
	}


}

