.breadcrumbs {
	margin-bottom: rem(15px);

	@media(min-width: $lg) {
		margin-bottom: 0;
	}

	.breadcrumb-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.breadcrumb-item {
		margin-right: rem(5px);
		font-size: rem(13px);
	}

	.breadcrumb-item:last-child {
		.breadcrumb-link {
			font-weight: $light;
		}
	}

	.breadcrumb-link {
		display: inline-block;
		margin-right: rem(5px);
		color: $color-dark;
		font-size: rem(12px);

		&:hover {
			color: $color-tertiary;
		}
	}
}
