.slider-media-wrap {
	position: relative;
	margin-bottom: rem(30px);

	.slider-media {
		position: relative;
	}

	.media-item {
		position: relative;
		height: rem(560px);
	}

	.media-image {
		position: absolute;
		height: rem(560px);

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.media-caption {
		display: flex;
		position: absolute;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		.slide-heading {
			margin-bottom: 0;
			color: $color-white;
			font-family: $font-stack-alt;
			font-size: rem(35px);
			line-height: rem(35px);

			@media (min-width: $lg) {
				font-size: rem(70px);
				line-height: rem(70px);
			}
		}

		.slide-content {
			color: $color-white;
			font-family: $font-stack-alt;
			font-size: rem(18px);

			@media (min-width: $lg) {
				font-size: rem(35px);
			}
		}

		.slide-link {
			padding: rem(10px) rem(20px);
			transition: all 0.3s ease-in-out;
			background: $color-white;
			color: $color-dark;
			font-size: rem(14px);
			text-transform: uppercase;
		}

		.slide-link:hover {
			background: $color-tertiary;
			color: $color-white;
		}
	}

	.active .slide-heading {
		animation: 1s 2s fadeInDown both;
	}

	.active .slide-content {
		animation: 1s 2s fadeInUp both;
	}

	.active .slide-link {
		animation: 1s 2s fadeInUp both;
	}
}
