.owl-nav {
	position: absolute;
	top: calc(50% - 30px);
	width: 100%;
	transform: translateY(-50%);
}

.owl-prev,
.owl-next {
	position: absolute;
	transition: all 0.3s ease;

	&::before {
		font-family: $font-icons;
		font-size: rem(24px);
	}

	&:hover {
		background: transparent !important;
		color: $color-tertiary !important;
	}

	span {
		display: none;
	}
}

.owl-prev {
	left: rem(-50px);

	&::before {
		content: "\e901";
	}
}

.owl-next {
	right: rem(-50px);

	&::before {
		content: "\e900";
	}
}
