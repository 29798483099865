.shop-heading {
	.product-title {
		display: flex;

		h1 {
			font-size: rem(28px);
		}

		.sale {
			font-size: rem(18px);
		}

		@media(min-width: $lg) {
			display: none;
		}
	}

	.product-description,
	.product-price {
		display: block;

		@media(min-width: $lg) {
			display: none;
		}
	}
}

.product-summary {
	.product-title {
		display: none;

		h1 {
			font-size: rem(38px);
		}

		.sale {
			font-size: rem(24px);
		}

		@media(min-width: $lg) {
			display: flex;
		}
	}

	.product-description,
	.product-price {
		display: none;

		@media(min-width: $lg) {
			display: block;
		}
	}

	.product-price {
		margin-bottom: rem(30px);
	}
}

.product-title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h1 {
		margin: 0;
		font-family: $font-stack-alt;
	}

	.sale {
		display: block;
		padding: 0 rem(10px);
		background: $color-accent;
		font-family: $font-stack-alt;
		font-size: rem(24px);
		font-weight: $bold;
	}
}

.product-description {
	font-size: rem(16px);

}

.product-price {
	.price {
		font-family: $font-stack-alt;
		font-size: rem(26px);
	}

	.price-from {
		color: $color-grey;
		font-family: $font-stack-alt;
		font-size: rem(22px);
		text-decoration: line-through;
	}
}

.product-sku {
	margin-bottom: rem(20px);
}

.product-attributes {
	.attribute-title {
		margin-bottom: rem(14px);
		font-size: rem(14px);
		font-weight: $regular;
		text-transform: uppercase;
	}

	.attribute-select {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: rem(30px);

		select {
			flex-grow: 1;
			margin-right: rem(25px);
			padding: rem(10px) rem(15px);
			border: rem(1px) solid $color-light;
		}

		a {
			color: $color-dark;
			text-decoration: underline;
		}
	}
}

.attribute-color {
	padding-bottom: rem(30px);

	.color-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.list-item {
		margin-right: rem(15px);
	}

	.label-radio {
		display: flex;
		align-items: center;
		height: rem(25px);
		margin-bottom: rem(5px);

		input {
			display: none;
		}

		input:checked + .label-text {
			border-color: $color-dark;
		}

		> .label-text {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			width: rem(34px);
			height: rem(34px);
			padding: rem(5px);
			transition: border-color 0.3s ease;
			border: 1px solid $color-light;
			border-radius: 50%;
			font-size: rem(21px);
			cursor: pointer;
		}

		.option-value {
			display: none;
		}
	}

}

.product-cart {
	margin-bottom: rem(30px);

	.quanity-wrap {
		display: flex;
		margin-bottom: rem(10px);
	}

	.quantity {
		border: 0;
		font-size: rem(18px);
		text-align: center;
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}
	}

	.button-group {
		display: flex;
		align-items: center;
	}

	.button-cart {
		flex-grow: 1;
		margin-right: rem(15px);
	}

	.button-wishlist {
		font-size: rem(24px);
	}
}

.product-extras {
	margin-bottom: rem(35px);

	.extras-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.list-item {
		display: flex;
		align-items: center;
		margin-bottom: rem(8px);
		font-size: rem(14px);
		font-weight: $light;

		span {
			display: inline-block;
			margin-right: rem(10px);
			font-size: rem(16px);
		}
	}
}

.product-share {
	display: flex;
	align-items: center;
	margin-bottom: rem(30px);

	.share-title {
		margin-right: rem(15px);
		font-family: $font-stack-alt;
		font-size: rem(21px);
		font-weight: $bold;
	}

	.share-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.share-item {
		margin-right: rem(7px);
	}

	.share-link {
		color: $color-dark;
		font-size: rem(20px);
	}
}

.product-info {
	.info-list {
		margin: 0 0 rem(15px);
		padding: 0;
		list-style: none;
	}

	.info-content {
		display: none;
		font-weight: $light;
	}

	.show {
		.lnr {
			transform: rotate(-180deg);
		}
	}

	.list-link,
	.sub-link {
		display: block;
		color: $color-dark;
		font-size: rem(14px);
	}

	.toggler {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(5px);
		padding-right: rem(6px);
		padding-bottom: rem(5px);
		border-bottom: rem(1px) solid $color-light;
		font-size: rem(14px);
		font-weight: $bold;
		letter-spacing: rem(1.1px);
		text-transform: uppercase;
		cursor: pointer;
	}

	.lnr {
		transition: transform 0.3s ease;
		font-size: rem(12px);
	}
}
