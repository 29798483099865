* {
	outline: 0 !important;
}

html {
	font-size: 1rem;
}

body {
	color: $color-dark;
	font-family: $font-stack;
	font-weight: $regular;
	line-height: $line-height;
	text-rendering: optimizeLegibility;

	a {
		transition: color 0.3s ease 0s;

		&:hover {
			text-decoration: none;
		}
	}
}

.sr-only {
	display: none;
}
