.lb-outerContainer {
	border-radius: 0;
}

.lb-nav .lb-next,
.lb-nav .lb-prev {
	position: relative;
	background: none !important;

	&::before {
		display: flex;
		position: absolute;
		align-items: center;
		width: 100%;
		height: 100%;
		color: $color-dark;
		font-family: $font-icons;
		font-size: rem(32px);
	}
}

.lb-nav .lb-next {
	&::before {
		content: "\e900";
		justify-content: flex-end;
		padding-right: rem(24px);
	}
}

.lb-nav .lb-prev {
	&::before {
		content: "\e901";
		justify-content: flex-start;
		padding-left: rem(24px);
	}
}

.lb-data .lb-close {
	position: relative;
	background: none !important;

	&::before {
		content: "\e915";
		position: absolute;
		top: rem(10px);
		left: 0;
		width: 100%;
		height: 100%;
		color: $color-white;
		font-family: $font-icons;
		font-size: rem(28px);
	}
}

.lb-caption {
	font-family: $font-stack-alt;
	font-size: rem(18px) !important;
}
