.nav-social {
	display: none;

	@media (min-width: $lg) {
		display: block;
	}

	.nav-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.nav-item {
		display: flex;
		align-items: flex-end;
		margin-left: rem(5px);
	}

	.nav-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(23px);
		height: rem(23px);
		border-radius: rem(4px);
		background: $color-dark;
		color: $color-white;
		font-size: rem(14px);
		line-height: rem(20px);
	}
}
