.sidebar {
	.widget {
		margin-bottom: rem(30px);

		.widget-title {
			margin: rem(30px) 0 rem(5px);
			padding-bottom: rem(5px);
			border-bottom: rem(1px) solid $color-light;
			font-family: $font-stack-alt;
			font-size: rem(16px);
		}
	}
}
