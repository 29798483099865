.nav-main {
	display: none;

	@media (min-width: $lg) {
		display: block;
	}

	.nav-list {
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.nav-item {
		display: flex;
		align-items: flex-end;
		padding: rem(14px) 0 rem(20px);
		transition: all 0.1s ease;

		&:hover {
			border-bottom: 3px solid $color-dark;
		}
	}

	.nav-item:hover {
		border-bottom: 3px solid $color-dark;
		color: $color-tertiary;

		> .sub-menu {
			top: 100%;
			transform: scaleX(1);
			opacity: 1;
		}
	}

	.nav-item:last-child {
		padding: rem(17px) 0;

		&:hover {
			border-bottom: 0;
		}

		.nav-link {
			padding: rem(5px) rem(8px);
			background: $color-accent;
		}

		.nav-link:hover {
			color: $color-white !important;
		}
	}

	.nav-link {
		display: block;
		position: relative;
		color: $color-dark;
		font-size: rem(13px);
		font-weight: $light;
		text-transform: uppercase;
	}
}

.sub-menu {
	position: absolute;
	z-index: 10000;
	top: 130%;
	left: 0;
	width: calc(100% - 30px);
	margin: 0 rem(15px);
	padding: rem(30px);
	list-style: none;
	transform: scaleX(0);
	transition: top 0.3s ease 0s, opacity 0.3s;
	border: 1px solid $color-tertiary;
	opacity: 1;
	background: $color-white;

	.sub-list {
		display: flex;
		justify-content: space-around;
		margin: 0;
		padding: 0;
		list-style: none;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}

	.sub-link {
		margin-bottom: rem(15px);
		color: $color-dark;
		font-family: $font-stack-alt;
		font-size: rem(18px);
		font-weight: $bold;
	}

	a {
		display: block;
		color: $color-dark;
		font-size: rem(14px);
		font-weight: $light;

		&:hover {
			color: $color-tertiary;
		}
	}
}
