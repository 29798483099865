.product-list-container {
	display: flex;

	.show-filters {
		display: block;
		position: fixed;
		z-index: 10005;
		top: 50%;
		left: 0;
		transform: translateY(-50%);

		@media (min-width: $lg) {
			display: none;
		}

		.button-filters {
			padding: rem(6px) rem(12px);
			background: $color-accent;
			font-size: rem(24px);
		}
	}

	.product-list-wrap {
		width: 100%;

		@media (min-width: $lg) {
			width: 75%;
		}
	}
}

