.form {
	.form-group {
		display: flex;
		align-items: flex-end;

		.input {
			outline: 0 !important;
			color: $color-dark;
			font-size: rem(14px);
		}
	}

	.form-caption {
		margin-top: rem(15px);
		font-size: rem(12px);
	}
}
