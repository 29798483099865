.attribute-list {
	display: flex;
	align-items: center;

	.attribute {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-right: rem(15px);
		padding: rem(6px) rem(6px) rem(6px) rem(10px);
		border: 1px solid $color-light;
		font-size: rem(14px);
		font-weight: $light;

		.button {
			margin: 0 0 0 rem(15px);
			padding: 0;
			line-height: rem(14px);
		}
	}
}
