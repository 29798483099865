.product-list-header {

	.list-header-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(15px);
	}

	.result-count {
		color: $color-grey;
		font-size: rem(14px);
	}

	.orderby {
		padding: rem(4px) rem(8px);
		border: 1px solid $color-light;
		color: $color-grey;
		font-size: rem(14px);
	}
}
