.widget-attributes {

	.attribute-title {
		margin-bottom: rem(15px);
		padding-bottom: rem(5px);
		border-bottom: rem(1px) solid $color-light;
		font-family: $font-stack-alt;
		font-size: rem(16px);
	}

	.attribute-options {
		display: flex;
		margin-bottom: rem(15px);
	}

	.options-column {
		flex-grow: 1;
	}

	.option-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.label-checkbox {
		display: flex;
		align-items: center;
		height: rem(25px);
		margin-bottom: rem(5px);

		input {
			display: none;
		}

		input:checked + .label-text::before {
			border-color: $color-dark;
		}

		input:checked + .label-text::after {
			border-color: $color-dark;
		}

		> .label-text {
			display: flex;
			position: relative;
			align-items: center;
			padding-left: rem(25px);
			font-size: rem(14px);
			line-height: 1.2;
			cursor: pointer;
		}

		> .label-text::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: rem(18px);
			height: rem(18px);
			transition: all 0.5s;
			border: rem(1px) solid $color-light;
			background-color: $color-white;
		}

		> .label-text::after {
			content: "";
			position: absolute;
			top: rem(5px);
			left: rem(4px);
			width: rem(9px);
			height: rem(5px);
			transform: rotate(-45deg);
			transition: all 0.5s;
			border-bottom: rem(2px) solid $color-white;
			border-left: rem(2px) solid $color-white;
			border-top-color: transparent;
			border-right-color: transparent;
		}
	}

	.option-color {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		width: rem(17px);
		height: rem(17px);
		margin-right: rem(8px);
		border: 1px solid $color-light;
		border-radius: 50%;
		font-size: rem(11px);
	}

	.option-value {
		text-transform: uppercase;
	}
}
