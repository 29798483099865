.section-heading {
	margin-bottom: rem(30px);
	text-align: center;

	.section-title {
		font-family: $font-stack-alt;
		font-size: rem(36px);
	}

	.section-link {
		color: $color-dark;
		font-size: rem(14px);
		text-decoration: underline;
		text-transform: uppercase;

		&:hover {
			color: $color-tertiary;
		}
	}
}

.shop-heading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: rem(30px);

	.shop-title {
		display: inline-block;
		margin: 0 auto rem(5px);
		border-bottom: rem(1px) solid $color-light;
		font-family: $font-stack-alt;
		font-size: rem(26px);
	}

	.shop-subtitle {
		display: inline-block;
		margin: 0 auto;
		font-size: rem(12px);
	}
}
