.banner {
	display: flex;
	flex-direction: column;
	background: $color-light;

	@media (min-width: $lg) {
		flex-direction: row;
	}

	.banner-image {
		position: relative;
		overflow: hidden;

		@media (min-width: $lg) {
			width: 60%;
			height: rem(310px);
		}
	}

	.banner-overlay {
		position: absolute;
		z-index: 1;

		.icon-plus {
			position: absolute;
			color: $color-primary;
		}

		.icon-1 {
			top: rem(45px);
			left: rem(170px);
			font-size: rem(48px);
		}

		.icon-2 {
			top: rem(110px);
			left: rem(40px);
			font-size: rem(86px);
		}

		.icon-3 {
			top: rem(218px);
			left: rem(75px);
			font-size: rem(28px);
		}

		.icon-4 {
			top: rem(245px);
			left: rem(40px);
			font-size: rem(34px);
		}

		.icon-5 {
			top: rem(237px);
			left: rem(95px);
			font-size: rem(54px);
		}
	}

	.banner-caption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: rem(30px);

		@media (min-width: $lg) {
			width: 40%;
		}
	}

	.banner-subheading {
		margin-bottom: 0;
		color: $color-grey;
		font-size: rem(24px);
		font-weight: $regular;

		span {
			color: $color-primary;
		}
	}


	.banner-heading {
		font-family: $font-stack-alt;
		font-size: rem(44px);
	}

	.banner-text {
		letter-spacing: rem(2px);
	}

	.banner-link {
		color: $color-dark;
		font-size: rem(14px);
		font-weight: $bold;
		text-decoration: underline;

		&:hover {
			color: $color-grey;
		}
	}
}
