.widget-range {
	.range-values {
		display: flex;
		justify-content: space-between;
		margin-top: rem(20px);
	}

	.range-handlers {
		position: relative;
		margin: rem(20px) 0 rem(50px);
	}

	.input-range {
		position: absolute;
		width: calc(50% + 8px);
		background: transparent;
		-webkit-appearance: none;
	}

	.min {
		left: 0;
	}

	.max {
		right: 0;
	}

	.input-range::-webkit-slider-thumb {
		width: rem(16px);
		height: rem(16px);
		margin-top: rem(-8px);
		border: rem(1px) solid $color-dark;
		border-radius: 50%;
		background: $color-white;
		cursor: pointer;
		-webkit-appearance: none;
	}

	.input-range::-moz-range-thumb,
	.input-range::-ms-thumb {
		width: rem(16px);
		height: rem(16px);
		border: rem(1px) solid $color-dark;
		border-radius: 50%;
		background: $color-white;
		cursor: pointer;
	}

	.input-range:focus {
		outline: none;
	}

	.input-range::-webkit-slider-runnable-track {
		width: 100%;
		height: rem(1px);
		background: $color-light;
		cursor: pointer;
	}

	.input-range:focus::-webkit-slider-runnable-track {
		background: $color-light;
	}

	.input-range::-moz-range-track {
		width: 100%;
		height: rem(1px);
		background: $color-light;
		cursor: pointer;
	}

	.input-range::-ms-track {
		width: 100%;
		height: rem(1px);
		border-color: transparent;
		background: transparent;
		color: transparent;
		cursor: pointer;
	}

	.input-range::-ms-fill-lower {
		background: $color-dark;
	}

	.input-range:focus::-ms-fill-lower {
		background: $color-dark;
	}

	.input-range::-ms-fill-upper {
		background: $color-dark;
	}

	.input-range:focus::-ms-fill-upper {
		border-radius: 2.6px;

	}
}
