.nav-mobile,
.nav-item .nav-list {
	position: fixed;
	z-index: 10009;
	top: 0;
	left: -100vw;
	width: 100%;
	height: 100%;
	min-height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	background: $color-white;
}

.navigation {
	display: flex;
	position: absolute;
	z-index: 1000000;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: rem(55px);
	padding: 0 rem(30px);

	.button-hide-menu,
	.button-back {
		color: $color-white;
		font-size: rem(24px);
	}
}

.nav-mobile {
	.nav-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.nav-header {
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(55px);
		background: $color-tertiary;
		color: $color-white;
	}

	.nav-item {
		padding: rem(10px) rem(30px);
		border-bottom: 1px solid $color-light;
	}

	.has-children {
		position: relative;

		&::after {
			content: "\e900";
			position: absolute;
			right: rem(30px);
			font-family: $font-icons;
			font-size: rem(14px);
		}
	}

	.nav-link {
		color: $color-dark;
	}


}
