.cta {
	position: relative;
	height: rem(640px);

	.cta-image {
		position: relative;
		height: rem(640px);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.cta-content {
		position: absolute;
		top: 20%;
		left: 0;

		@media (min-width: $lg) {
			top: 0;
			left: 0;
			width: 40%;
			padding: rem(50px) rem(100px);
		}

		.cta-title {
			color: $color-white;
			font-family: $font-stack-alt;
			font-size: rem(60px);
			line-height: rem(70px);
			text-align: center;

			@media (min-width: $lg) {
				color: $color-dark;
				text-align: left;
			}
		}

		.cta-subtitle {
			margin-bottom: rem(60px);
			color: $color-white;
			font-size: rem(18px);
			font-weight: $regular;
			letter-spacing: rem(2px);
			text-align: center;

			@media (min-width: $lg) {
				color: $color-dark;
				text-align: left;
			}
		}

		.cta-link {
			display: block;
			width: rem(120px);
			margin: 0 auto;
			padding: rem(10px) rem(20px);
			transition: all 0.3s ease;
			background: $color-white;
			color: $color-dark;
			font-size: rem(14px);
			text-align: center;
			text-transform: uppercase;

			@media (min-width: $lg) {
				display: inline-block;
				width: auto;
				background: $color-dark;
				color: $color-white;

			}
		}

		.cta-link:hover {
			background: rgba($color-tertiary, 0.8);
			color: $color-dark;
		}
	}
}
