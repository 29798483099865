.nav-shop {

	@media (min-width: $lg) {
		margin-right: rem(30px);
	}

	.nav-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.nav-item {
		display: flex;
		align-items: flex-end;
		margin-left: rem(15px);

		&.account {
			display: none;

			@media (min-width: $lg) {
				display: block;
			}
		}

		&.location {
			display: none;

			@media (min-width: $lg) {
				display: block;
			}
		}

		&.wishlist {
			display: none;

			@media (min-width: $lg) {
				display: block;
			}
		}

		&.count {
			display: none;

			@media (min-width: $lg) {
				display: block;
			}
		}
	}

	.nav-link {
		display: block;
		color: $color-dark;
		font-size: rem(28px);
		line-height: rem(28px);

		@media (min-width: $lg) {
			font-size: rem(20px);
			line-height: rem(20px);
		}

		&.items {
			color: $color-tertiary;
			font-size: rem(14px);
			font-weight: $regular;
		}
	}
}
