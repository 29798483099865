.product-showcase {
	display: flex;
	flex-direction: column;

	@media (min-width: $md) {
		flex-direction: row;
	}
}

.product-thumbs {

	@media (min-width: $md) {
		margin-right: rem(15px);
	}

	.thumb-list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $md) {
			flex-direction: column;
		}
	}

	.thumb-item {
		margin-bottom: rem(3px);
		overflow: hidden;

		@media (min-width: $md) {
			margin-bottom: rem(15px);
		}
	}

	.thumb-link {
		display: block;
		position: relative;

		&::before {
			content: "\e902";
			display: flex;
			position: absolute;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
			background: rgba($color-dark, 0.5);
			color: $color-white;
			font-family: $font-icons;

			@media (min-width: $lg) {
				content: "\e900";
			}
		}
	}

	.thumb-link:hover {
		&::before {
			opacity: 1;
		}
	}
}

.product-details-images {
	position: relative;
	width: 100%;

	.image-list {
		position: relative;
		height: rem(400px);
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $md) {
			height: rem(600px);
		}
	}

	.image-item {
		display: none;
		position: absolute;

		&:first-child {
			display: block;
		}
	}

	.image-link {
		display: block;
		position: relative;

		&::before {
			content: "\e914";
			display: flex;
			position: absolute;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
			background: rgba($color-dark, 0.5);
			color: $color-white;
			font-family: $font-icons;
			font-size: rem(24px);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.image-link:hover {
		&::before {
			opacity: 1;
		}
	}
}

.product-set {
	margin: rem(30px) 0;

	@media (min-width: $lg) {
		margin: 0;
	}

	.set-title {
		display: block;
		margin: rem(10px) 0;
		font-family: $font-stack-alt;
		font-size: rem(21px);
		font-weight: $bold;
	}
}
