@font-face {
	font-family: $font-icons;
	src: url("../icons/icomoon.eot?mad2e1");
	src: url("../icons/icomoon.eot?mad2e1#iefix") format("embedded-opentype"),
		 url("../icons/icomoon.ttf?mad2e1") format("truetype"),
		 url("../icons/icomoon.woff?mad2e1") format("woff"),
		 url("../icons/icomoon.svg?mad2e1#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	font-family: $font-icons !important;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.icon-arrow-left::before {
	content: "\e917";
}

.icon-equalizer::before {
	content: "\e916";
}

.icon-menu::before {
	content: "\e90a";
}

.icon-cross::before {
	content: "\e915";
}

.icon-magnifier::before {
	content: "\e914";
}

.icon-trash::before {
	content: "\e913";
}

.icon-return::before {
	content: "\e90b";
}

.icon-shipping::before {
	content: "\e90c";
}

.icon-security::before {
	content: "\e90d";
}

.icon-bag::before {
	content: "\e90e";
}

.icon-favorite::before {
	content: "\e90f";
}

.icon-location::before {
	content: "\e910";
}

.icon-profile::before {
	content: "\e911";
}

.icon-right::before {
	content: "\e900";
}

.icon-left::before {
	content: "\e901";
}

.icon-down::before {
	content: "\e902";
}

.icon-up::before {
	content: "\e903";
}

.icon-circle::before {
	content: "\e912";
}

.icon-minus::before {
	content: "\e904";
}

.icon-plus::before {
	content: "\e905";
}

.icon-envelope::before {
	content: "\e906";
}

.icon-youtube-play::before {
	content: "\e907";
}

.icon-instagram::before {
	content: "\e908";
}

.icon-facebook::before {
	content: "\e909";
}
