@font-face {
	font-family: "Didot";
	src: url("../font/Didot-Italic.eot");
	src: url("../font/Didot-Italic.eot?#iefix") format("embedded-opentype"),
		 url("../font/Didot-Italic.woff2") format("woff2"),
		 url("../font/Didot-Italic.woff") format("woff"),
		 url("../font/Didot-Italic.ttf") format("truetype"),
		 url("../font/Didot-Italic.svg#Didot-Italic") format("svg");
	font-weight: $regular;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Didot";
	src: url("../font/Didot.eot");
	src: url("../font/Didot.eot?#iefix") format("embedded-opentype"),
		 url("../font/Didot.woff2") format("woff2"),
		 url("../font/Didot.woff") format("woff"),
		 url("../font/Didot.ttf") format("truetype"),
		 url("../font/Didot.svg#Didot") format("svg");
	font-weight: $regular;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Didot";
	src: url("../font/Didot-Bold.eot");
	src: url("../font/Didot-Bold.eot?#iefix") format("embedded-opentype"),
		 url("../font/Didot-Bold.woff2") format("woff2"),
		 url("../font/Didot-Bold.woff") format("woff"),
		 url("../font/Didot-Bold.ttf") format("truetype"),
		 url("../font/Didot-Bold.svg#Didot-Bold") format("svg");
	font-weight: $bold;
	font-style: normal;
	font-display: swap;
}
