.button {
	border: 0;
	outline: 0 !important;
	background: transparent;

	&.button-search {
		font-size: rem(18px);
		line-height: rem(18px);
	}

	&.button-subsribe {
		padding: rem(8px) rem(16px);
		background: $color-dark;
		color: $color-light;
		font-size: rem(18px);
		font-size: rem(14px);
	}

	&.button-change {
		font-size: rem(14px);
	}

	&.button-cart {
		position: relative;
		padding: rem(10px) rem(15px);
		background: $color-dark;
		color: $color-white;
		font-size: rem(14px);
		font-weight: $light;
		text-transform: uppercase;

		&::after {
			content: "\e82e";
			position: absolute;
			top: 50%;
			right: rem(15px);
			transform: translateY(-50%);
			font-family: $font-icons;
			font-size: rem(21px);
		}
	}
}
