.footer-widgets {
	margin-top: rem(45px);
	padding: rem(30px) 0;
	border-top: 1px solid $color-light;

	@media (min-width: $lg) {
		padding: rem(75px) 0;
	}

	.widget {
		margin-bottom: rem(15px);

		p {
			margin-bottom: 0;
		}
	}

	.widget-title {
		margin-bottom: rem(15px);
		font-family: $font-stack-alt;
		font-size: rem(21px);
		font-weight: $bold;
	}

	.widget-text {
		font-size: rem(14px);
	}

	.widget-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.widget-link {
		color: $color-dark;
		font-size: rem(14px);

		&:hover {
			color: $color-tertiary;
		}
	}
}

.footer-bottom {
	padding: rem(15px) 0;
	background: $color-light;


	.copyright {
		margin-top: rem(15px);
		font-size: rem(13px);
		text-align: center;

		@media (min-width: $lg) {
			margin-top: 0;
		}
	}

	.payments {
		text-align: center;
	}
}
