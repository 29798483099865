.product-item {
	position: relative;
}

.product-image {
	position: relative;
	margin-bottom: rem(20px);
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
	}
}

.product-link {
	display: block;
	color: $color-dark;

	img {
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		img {
			transform: scale(1.05);
		}
	}

	.new,
	.sale {
		display: flex;
		position: absolute;
		align-items: center;
		justify-content: center;
		font-family: $font-stack-alt;
		font-size: rem(18px);
		font-weight: $bold;
	}

	.new {
		z-index: 1000;
		top: rem(20px);
		left: rem(20px);
		width: rem(60px);
		height: rem(60px);
		padding: rem(10px);
		border-radius: 50%;
		background: rgba($color-secondary, 0.8);
		line-height: rem(60px);
	}

	.sale {
		z-index: 1000;
		top: rem(270px);
		padding: 0 rem(15px);
		background: rgba($color-accent, 0.8);
	}

	.color-swatches {
		display: flex;
		position: absolute;
		z-index: 1000;
		right: 0;
		bottom: rem(20px);
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: rem(11px);

		.swatch-item {
			width: rem(17px);
			height: rem(17px);
			margin-right: rem(5px);
			border: 1px solid $color-light;
			border-radius: 50%;
			background: $color-white;
			line-height: rem(15px);
			text-align: center;
		}
	}
}

.product-list {
	.product-item {
		margin-bottom: rem(30px);
	}
}

.product-row {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	justify-content: space-between;
	padding: 0 rem(15px);

	@media (min-width: $lg) {
		flex-direction: row;
	}

	.title {
		font-family: $font-stack-alt;
		font-size: rem(16px);
		font-weight: $bold;
	}

	.price {
		font-family: $font-stack-alt;
	}

	.category {
		font-size: rem(14px);
		font-weight: $light;
	}

	.shop-now {
		color: $color-dark;
		font-size: rem(14px);
		font-weight: $light;
		text-decoration: underline;

		&:hover {
			color: $color-tertiary;
		}
	}

	.description {
		font-size: rem(14px);

		a {
			color: $color-dark;
		}

		a:hover {
			color: $color-tertiary;
		}
	}
}
