.cart-overview {
	position: fixed;
	z-index: 10007;
	top: 0;
	right: rem(-600px);
	height: 100%;
	min-height: 100%;
	padding: rem(15px) rem(30px);
	overflow-x: hidden;
	overflow-y: scroll;
	border: 1px solid $color-tertiary;
	background: $color-white;

	@media (max-width: $sm) {
		width: 100%;
	}

	.overview-hide {
		.button-hide {
			padding: 0;
			font-size: rem(24px);
		}
	}

	.overview-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: rem(15px) 0;

		h3 {
			margin: 0;
			font-family: $font-stack-alt;
			font-size: rem(16px);
		}

		.items {
			font-size: rem(14px);
			font-weight: $light;
		}
	}

	.overview-item {
		display: flex;
		margin-bottom: rem(15px);
		padding-bottom: rem(15px);
		border-bottom: rem(1px) solid $color-light;
	}

	.overview-item:last-child {
		border-bottom: 0;
	}


	.overview-image {
		width: 40%;
	}

	.overview-details {
		position: relative;
		width: 60%;
		padding-left: rem(15px);
	}

	.details-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(5px);

		h4 {
			margin: 0;
			font-family: $font-stack-alt;
		}

		.sale {
			padding: 0 rem(5px);
			background: $color-accent;
			font-family: $font-stack-alt;
			font-size: rem(14px);
			font-weight: $bold;
		}
	}

	.details-description {
		p {
			margin: 0;
			font-size: rem(13px);
			line-height: rem(14px);
		}
	}

	.details-footer {
		display: flex;
		position: absolute;
		bottom: 0;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;
		padding-right: rem(5px);
	}

	.price-group {

		.price-from,
		.price {
			font-family: $font-stack-alt;
			font-size: rem(14px);
			font-weight: $bold;
		}

		.price-from {
			color: lighten($color-grey, 15%);
			text-decoration: line-through;
		}
	}


	.remove {
		.button-remove {
			transition: color 0.3s ease-in-out;
		}

		.button-remove:hover {
			color: $color-danger;
		}
	}
}
